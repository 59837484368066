import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertColor, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, InputLabel, makeStyles, MenuItem, Paper, Select, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar, GridToolbarFilterButton, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarQuickFilter } from "@mui/x-data-grid";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LinkIcon from '@mui/icons-material/Link';
import { SyntheticEvent, useEffect, useState } from "react";
import React from "react";
import { useNavigate } from "react-router";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import UploadForm from "../Upload/UploadForm/UploadForm";
import RecordFilterSelect from "../../ContractList/ContractFilter/RecordFilterSelect/RecordFilterSelect";
import apiClient from "../../../services/apiClient";
import { useAuth } from "../../AuthProvider";
import Guard from "../../shared/Guard/Guard";
import { Role } from "../../../data";
import ConfirmDialog from "../../ui/mui/ConfirmDialog/ConfirmDialog";
import { useMatomo } from "@jonkoops/matomo-tracker-react";




interface filterType {
    natcos: [];
}


export default function InputData() {
    const { profile } = useAuth();
    const [rows, setRows] = useState<any[]>([]);
    const [rowsRules, setRowsRules] = useState<any[]>([]);
    const [rowsProducts, setRowsProducts] = useState<any[]>([]);
    const [rowsGlobal, setRowsGlobal] = useState<any[]>([]);
    const [rowsDataHubProducts, setRowsDatahubProducts] = useState<any[]>([]);

    const [rowsDatahubQuantities, setRowsDatahubQuantities] = useState<any[]>([]);

    const [records, setRecords] = useState<any>([]);
    const [suppliersRecords, setSuppliersRecords] = useState<any>([]);
    const [subcategoriesRecords, setSubcategoriesRecords] = useState<any>([]);
    const [productDataTypes, setProductDataTypes] = useState<any>([]);

    const [expanded, setExpanded] = useState<string | false>(false);
    const [saveClicked, setSaveClicked] = useState<boolean>(false);
    const [deleteClicked, setDeleteClicked] = useState<boolean>(false);


    const [updateGrid, setUpdateGrid] = useState<boolean>(false);
    const [updateGridProduct, setUpdateGridProduct] = useState<boolean>(false);

    const [refreshList, setRefreshList] = useState<boolean>(false);

    const [selectedFiltersEdit, setSelectedFiltersEdit] = useState<filterType>({
        natcos: [],
    });
    const [selectedFiltersEditPre, setSelectedFiltersEditPre] = useState<any>({
        natcos: [],
    });
    const [defaultSelection, setDefaultSelection] = useState<any>({
        natcos: [],
    });
    const [associatedNatcosEdit, setAssociatedNatcosEdit] = useState([]);
    const [associatedNatcosEditPre, setAssociatedNatcosEditPre] = useState([]);
    const [natcoRules, setNatcoRules] = useState([]);

    const [selectedNatco, setSelectedNatco] = useState(profile?.isCustomer ? profile.natco.code : '');
    const [isAll, setAll] = useState<boolean>(false);
    const [dataHubCleaned, setDatahubCleaned] = useState<boolean>(false);
    const [refreshproductList, setRefreshProductList] = useState<boolean>(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openConfirmProduct, setOpenConfirmProduct] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [pendingUpdate, setPendingUpdate] = useState<any>(null);
    const [pendingUpdateProduct, setPendingUpdateProduct] = useState<any>(null);
    const [hasInitialized, setHasInitialized] = useState(false);
    const fileUrl = 'https://buyin365.sharepoint.com/:w:/r/sites/DigitalTeam/_layouts/15/Doc.aspx?sourcedoc=%7BFBBCA507-C149-470F-B532-5871EAAA133F%7D&file=Deliveries_and_Forecast_ADMIN_Guide_V01.docx&action=default&mobileredirect=true';
    const { trackPageView } = useMatomo();

    trackPageView({
        documentTitle: 'Tools/Forecast_Deliveries',
        href: `${window.location.origin}/` + 'Tools/Forecast_Deliveries'
    });

    const [alert, setAlert] = useState<{
        severity: AlertColor | undefined;
        message: string;
    }>();


    const CustomToolbar = () => (
        <GridToolbarContainer sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {/* @ts-ignore */}
            <GridToolbarColumnsButton />  {/* Column visibility */}
            {/* @ts-ignore */}
            <GridToolbarDensitySelector />  {/* Density selection */}
            {/* @ts-ignore */}
            <GridToolbarFilterButton />
            <GridToolbarQuickFilter sx={{ marginLeft: 'auto' }} />  {/* Search */}

        </GridToolbarContainer>
    );

    const navigate = useNavigate();

    const section = {
        predefinition: 'Pre Define Suppliers, Subcategories, Natcos',
        rules: 'Define Rules',
        products: 'Product List',
        global: 'Upload Global Data',
        natco: 'Upload Data By Natco',
        datahub: 'DataHub interactions',
        userGuide: 'User Guide',
    };

    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }: any) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));


    useEffect(() => {
        apiClient
            .get(`/productDataRule`)
            .then((response) => {
                setRowsRules(response.data.records.map((ru: any) => ({
                    ...ru,
                    natcoCode: ru.natco?.code || null, // Use optional chaining to avoid errors
                    type: ru.productDataType?.name || null, // Handle missing productDataType
                })));


            })
            .catch((error) => {
                //TODO: handle error response
                console.log(error);
            });
    }, [saveClicked, deleteClicked])

    useEffect(() => {
        if (!profile?.isCustomer) {

            apiClient
                .get(`/productDataItem`)
                .then((response) => {
                    setRowsProducts(response.data.records);

                })
                .catch((error) => {
                    //TODO: handle error response
                    console.log(error);
                });
        }
    }, [refreshproductList, updateGridProduct])

    useEffect(() => {
        apiClient.get("/natcoData", { params: { natcoCode: selectedNatco } })
            .then((response) => {
                setRows(response.data.records.map((item: any) => ({
                    ...item,
                    id: item.id,
                    name: item.productDataItem.name,
                    type: item.productDataType.name,
                    supplier: item.productDataItem.supplier.name,

                })));

            })
            .catch((e) => {
                console.log(e);
            });


    }, [selectedNatco, saveClicked, refreshList, updateGrid])
    useEffect(() => {
        if (!profile?.isCustomer) {
            apiClient.get("/natcoData", {})
                .then((response) => {

                    setRowsGlobal(response.data.records.map((item: any) => ({
                        ...item,
                        id: item.id,
                        name: item.productDataItem.name,
                        type: item.productDataType.name,
                        natco: item.productDataItem.natco.code,
                        supplier: item.productDataItem.supplier.name,

                    })));
                })
                .catch((e) => {
                    console.log(e);
                });
        }

    }, [saveClicked, updateGrid, refreshList])

    useEffect(() => {
        apiClient
            .get(`/natcos`, {
                params: { toCollect: true },
            })
            .then((response) => {
                setRecords(
                    response.data.records.map((record: any) => ({
                        id: record.id,
                        name: record.code,
                    })),
                );

            })
            .catch((error) => {
                //TODO: handle error response
                console.log(error);
            });
    }, [])

    useEffect(() => {
        apiClient
            .get(`/natcos`, {
                params: { isForForecast: true },
            })
            .then((response) => {
                const transformedData = response.data.records.map((record: any) => ({
                    id: record.id,
                    name: record.code,
                }));

                // console.log(">>tranformedData pre", transformedData);
                // transformedData.toSorted((a: any, b: any) => a.name.localeCompare(b.name));
                // console.log(">>tranformedData after", transformedData);

                setAssociatedNatcosEditPre(transformedData);

                if (!hasInitialized) {
                    setDefaultSelection({ natcos: transformedData });
                    setSelectedFiltersEditPre({ natcos: transformedData });
                }

            })
            .catch((error) => {
                //TODO: handle error response
                console.log(error);
            });
    }, [])

    useEffect(() => {
        apiClient
            .get(`/productDataType`)
            .then((response) => {
                setProductDataTypes(
                    response.data.records
                );
                console.log(">>productDataTypes", productDataTypes);
            })
            .catch((error) => {
                //TODO: handle error response
                console.log(error);
            });
    }, [])

    useEffect(() => {
        apiClient
            .get(`/suppliers`)
            .then((response) => {
                setSuppliersRecords(
                    response.data.records.map((record: any) => ({
                        id: record.id,
                        name: record.name,
                    })),
                );

            })
            .catch((error) => {
                //TODO: handle error response
                console.log(error);
            });
    }, [])

    useEffect(() => {
        apiClient
            .get(`/subcategories`)
            .then((response) => {
                setSubcategoriesRecords(
                    response.data.records.map((record: any) => ({
                        id: record.id,
                        name: record.name,
                    })),
                );

            })
            .catch((error) => {
                //TODO: handle error response
                console.log(error);
            });
    }, [])



    useEffect(() => {
        if (!profile?.isCustomer) {

            apiClient
                .get(`/natcoData/getDatahubProducts`)
                .then((response) => {
                    console.log("products datahub", response.data);
                    setRowsDatahubProducts(response.data);
                })
                .catch((error) => {
                    //TODO: handle error response
                    console.log(error);
                });
        }
    }, [dataHubCleaned, refreshList, refreshproductList])

    useEffect(() => {
        if (!profile?.isCustomer) {

            apiClient
                .get(`/natcoData/getDatahubQuantities`)
                .then((response) => {
                    console.log("quantities datahub", response.data);
                    setRowsDatahubQuantities(response.data);
                })
                .catch((error) => {
                    //TODO: handle error response
                    console.log(error);
                });
        }
    }, [dataHubCleaned, refreshList, refreshproductList])

    useEffect(() => {
        if (alert) {
            const timer = setTimeout(() => {
                setAlert(undefined);
            }, 5000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [alert]);




    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            width: 215,
            editable: false,
            // cellClassName: 'super-app-theme--sticky', // Custom class for sticky effect
        },
        {
            field: 'supplier',
            headerName: 'Supplier',
            width: 100,
            editable: false,
        },
        {
            field: 'year',
            headerName: 'Year',
            width: 70,
            editable: false,
        },
        {
            field: 'type',
            headerName: 'Plan Item',
            width: 150,
            editable: false,
        },
        {
            field: 'jan',
            headerName: 'Jan',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'feb',
            headerName: 'Feb',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'mar',
            headerName: 'Mar',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'apr',
            headerName: 'Apr',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'may',
            headerName: 'May',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'jun',
            headerName: 'Jun',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'jul',
            headerName: 'Jul',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'aug',
            headerName: 'Aug',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'sep',
            headerName: 'Sep',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'oct',
            headerName: 'Oct',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'nov',
            headerName: 'Nov',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'dec',
            headerName: 'Dec',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'total',
            headerName: 'Total',
            width: 130,
            editable: false,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'comment',
            headerName: 'Comment',
            width: 200,
            editable: true,
            renderHeader: () => (
                <Tooltip title="No more than 500 characters" arrow>
                    <span style={{ fontWeight: 'bold' }}>Comment</span>
                </Tooltip>
            ),
        },

    ]

    const columnsGlobal: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            width: 215,
            editable: false,
            // headerClassName: 'super-app-theme--sticky-header',
            // cellClassName: 'super-app-theme--sticky-cell',        
        },
        {
            field: 'supplier',
            headerName: 'Supplier',
            width: 100,
            editable: false,
        },
        {
            field: 'year',
            headerName: 'Year',
            width: 70,
            editable: false,
        },
        {
            field: 'natco',
            headerName: 'Natco',
            width: 50,
            editable: false,
        },
        {
            field: 'type',
            headerName: 'Plan Item',
            width: 110,
            editable: false,
        },
        {
            field: 'jan',
            headerName: 'Jan',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'feb',
            headerName: 'Feb',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'mar',
            headerName: 'Mar',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'apr',
            headerName: 'Apr',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'may',
            headerName: 'May',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'jun',
            headerName: 'Jun',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'jul',
            headerName: 'Jul',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'aug',
            headerName: 'Aug',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'sep',
            headerName: 'Sep',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'oct',
            headerName: 'Oct',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'nov',
            headerName: 'Nov',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'dec',
            headerName: 'Dec',
            width: 80,
            editable: true,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'total',
            headerName: 'Total',
            width: 130,
            editable: false,
            valueFormatter: (params) => {
                if (params.value == null) return '';
                return new Intl.NumberFormat('de-DE').format(params.value); // Formats with dots (e.g., 1.234.567)
            },
        },
        {
            field: 'comment',
            headerName: 'Comment',
            width: 200,
            editable: true,
            renderHeader: () => (
                <Tooltip title="No more than 500 characters" arrow>
                    <span style={{ fontWeight: 'bold' }}>Comment</span>
                </Tooltip>
            ),
        },

    ]

    const columnsRules: GridColDef[] = [
        {
            field: 'year',
            headerName: 'Year',
            width: 100,
            editable: false,
        },
        {
            field: 'natcoCode',
            headerName: 'Natco Code',
            width: 150,
            editable: false,
        },

        {
            field: 'type',
            headerName: 'Type',
            width: 250,
            editable: false,
        },
    ]



    const handleSupplierChange = (rowId: number, supplierId: number) => {
        const updatedRows = rowsProducts.map((row) =>
            row.id === rowId
                ? { ...row, supplier: suppliersRecords.find((sup: any) => sup.id === supplierId) }
                : row
        );
        setRowsProducts(updatedRows);
    };

    const handleNatcoChange = (rowId: number, natcoId: number) => {
        const updatedRows = rowsProducts.map((row) =>
            row.id === rowId
                ? { ...row, natco: records.find((nat: any) => nat.id === natcoId) }
                : row
        );
        setRowsProducts(updatedRows);
    };

    const handleSubcategoryChange = (rowId: number, subcategoryId: number) => {
        const updatedRows = rowsProducts.map((row) =>
            row.id === rowId
                ? { ...row, subcategory: records.find((nat: any) => nat.id === subcategoryId) }
                : row
        );
        setRowsProducts(updatedRows);
    };

    const columnsProducts: GridColDef[] = [
        {
            field: 'uniqueIdentifier',
            headerName: 'Unique Identifier',
            width: 150,
            editable: false,
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 100,
            editable: true,
        },
        {
            field: 'localMaterialCode',
            headerName: 'Local Material Code',
            width: 150,
            editable: false,
        },

        {
            field: 'subcategory',
            headerName: 'Sub category',
            width: 250,
            renderCell: (params: any) => (
                <Typography>
                    {params.row?.subcategory?.name || 'Select Subcategory'}
                </Typography>
            ),
            renderEditCell: (params: any) => (
                <Select
                    value={params.row?.subcategory?.id || ''}
                    onChange={(e) => handleSubcategoryChange(params.id, e.target.value as number)}
                    fullWidth
                >
                    {subcategoriesRecords.map((subcategory: any) => (
                        <MenuItem key={subcategory.id} value={subcategory.id}>
                            {subcategory.name}
                        </MenuItem>
                    ))}
                </Select>
            ),
            editable: false,
        },
        {
            field: 'natco',
            headerName: 'Natco',
            width: 250,
            renderCell: (params: any) => (
                <Typography>
                    {params.row?.natco?.code || 'Select Natco'}
                </Typography>
            ),
            renderEditCell: (params: any) => (
                <Select
                    value={params.row?.natco?.id || ''}
                    onChange={(e) => handleNatcoChange(params.id, e.target.value as number)}
                    fullWidth
                >
                    {records.map((natco: any) => (
                        <MenuItem key={natco.id} value={natco.id}>
                            {natco.name}
                        </MenuItem>
                    ))}
                </Select>
            ),
            editable: false,
        },
        {
            field: 'supplier',
            headerName: 'Supplier',
            width: 250,
            renderCell: (params: any) => (
                <Typography>
                    {params.row?.supplier?.name || 'Select Supplier'}
                </Typography>
            ),
            renderEditCell: (params: any) => (
                <Select
                    value={params.row?.supplier?.id || ''}
                    onChange={(e) => handleSupplierChange(params.id, e.target.value as number)}
                    fullWidth
                >
                    {suppliersRecords.map((supplier: any) => (
                        <MenuItem key={supplier.id} value={supplier.id}>
                            {supplier.name}
                        </MenuItem>
                    ))}
                </Select>
            ),
            editable: false,
        },
        {
            field: 'smaId',
            headerName: 'SMA ID',
            width: 150,
            editable: false,
        },
        {
            field: 'groupRegion',
            headerName: 'Group Region',
            width: 150,
            editable: false,
        },
        {
            field: 'globalCategory',
            headerName: 'Global Category',
            width: 150,
            editable: false,
        },
        {
            field: 'groupProductSupplier',
            headerName: 'Group Product Supplier',
            width: 150,
            editable: false,
        },
        {
            field: 'groupProductName',
            headerName: 'Group Product Name',
            width: 150,
            editable: false,
        },
        {
            field: 'technicalPltaform',
            headerName: 'technical Platform',
            width: 150,
            editable: false,
        },
        {
            field: 'technology',
            headerName: 'Technology',
            width: 150,
            editable: false,
        },
        {
            field: 'gIdentifier',
            headerName: 'G Identifier',
            width: 150,
            editable: false,
        },
        {
            field: 'deviceType',
            headerName: 'Device Type',
            width: 150,
            editable: false,
        },
        {
            field: 'socSupplier',
            headerName: 'Soc Supplier',
            width: 150,
            editable: false,
        },
        {
            field: 'wifiChipSupplier',
            headerName: 'Wifi Chip Supplier',
            width: 150,
            editable: false,
        },
        {
            field: 'bcmProgram',
            headerName: 'BCM Program',
            width: 150,
            editable: false,
        },
        {
            field: 'productionLocation1',
            headerName: 'Production location 1',
            width: 150,
            editable: false,
        },
        {
            field: 'productionLocation2',
            headerName: 'Production Location 2',
            width: 150,
            editable: false,
        },
        {
            field: 'supplierInOut',
            headerName: 'Supplier In Out',
            width: 150,
            editable: false,
        },
        {
            field: 'productInOut',
            headerName: 'Product In Out',
            width: 150,
            editable: false,
        },

    ]

    const columnsDataHubProducts: GridColDef[] = [
        {
            field: 'ProductId',
            headerName: 'ProductId',
            width: 150,
            editable: true,
        },
        {
            field: 'Name',
            headerName: 'Name',
            width: 100,
            editable: true,
        },
        {
            field: 'SupplierId',
            headerName: 'SupplierId',
            width: 150,
            editable: true,
        },
        {
            field: 'SubCategoryId',
            headerName: 'SubCategoryId',
            width: 100,
            editable: true,
        },
        {
            field: 'LocalMaterialCode',
            headerName: 'Local Material Code',
            width: 100,
            editable: true,
        },
        {
            field: 'UniqueIdentifier',
            headerName: 'Unique Identifier',
            width: 100,
            editable: true,
        },
        {
            field: 'NatcoId',
            headerName: 'Natco Id',
            width: 100,
            editable: true,
        },
        {
            field: 'smaId',
            headerName: 'SMA ID',
            width: 100,
            editable: true,
        },
        {
            field: 'groupRegion',
            headerName: 'Group region',
            width: 100,
            editable: true,
        },
        {
            field: 'globalCategory',
            headerName: 'Global Category',
            width: 100,
            editable: true,
        },
        {
            field: 'groupProductSupplier',
            headerName: 'Group Product Supplier',
            width: 100,
            editable: true,
        },
        {
            field: 'technicalPltaform',
            headerName: 'Technial Platform',
            width: 100,
            editable: true,
        },
        {
            field: 'technology',
            headerName: 'Technology',
            width: 100,
            editable: true,
        },
        {
            field: 'gIdentifier',
            headerName: 'G Identifier',
            width: 100,
            editable: true,
        },
        {
            field: 'deviceType',
            headerName: 'Device Type',
            width: 100,
            editable: true,
        },
        {
            field: 'socSupplier',
            headerName: 'Soc Supplier',
            width: 100,
            editable: true,
        },
        {
            field: 'wifiChipSupplier',
            headerName: 'Wifi Chip Supplier',
            width: 100,
            editable: true,
        },
        {
            field: 'bcmProgram',
            headerName: 'Bcm program',
            width: 100,
            editable: true,
        }



    ]

    const columnsDataHubQuantities: GridColDef[] = [
        {
            field: 'ProductId',
            headerName: 'Product Id',
            width: 150,
            editable: true,
        },
        {
            field: 'Type',
            headerName: 'Type',
            width: 100,
            editable: true,
        },
        {
            field: 'Date',
            headerName: 'Date',
            width: 150,
            editable: true,
        },
        {
            field: 'Quantity',
            headerName: 'Quantity',
            width: 100,
            editable: true,
        },
        {
            field: 'QuantityId',
            headerName: 'Quantity Id',
            width: 100,
            editable: true,
        },
        {
            field: 'Comment',
            headerName: 'Comment',
            width: 100,
            editable: true,
            renderHeader: () => (
                <Tooltip title="No more than 500 characters" arrow>
                    <span>Comment</span>
                </Tooltip>
            ),
        }

    ]

    const handleRecordChangeEdit = (
        source: any,
        checked: any,
        updatedRecords: any,
        id: any,
        label: any,
        replaceAll: any, // Flag to indicate whether to replace or add/remove items
    ) => {
        const newFiltersEdit = JSON.parse(JSON.stringify(selectedFiltersEdit));
        if (replaceAll) {
            setAll(true);
            // Replace the entire selection
            newFiltersEdit[source] = updatedRecords;
        } else {
            setAll(false);
            if (checked) {
                newFiltersEdit[source] = [...(newFiltersEdit[source] || []), updatedRecords];
            } else {
                newFiltersEdit[source] = newFiltersEdit[source].filter(
                    (item: any) => item.id !== updatedRecords.id,
                );
            }



        }

        setSelectedFiltersEdit(newFiltersEdit);
    };


    const handleFilterCancelEdit = (source: string, id: any) => {
        const newFiltersEdit = JSON.parse(JSON.stringify(selectedFiltersEdit));
        newFiltersEdit.associatedNatcosEdit = associatedNatcosEdit;
        setSelectedFiltersEdit(newFiltersEdit);
    };

    const handleFilterApplyEdit = (source: any, id: any) => {
        const newFiltersEdit = JSON.parse(JSON.stringify(selectedFiltersEdit));
        setAssociatedNatcosEdit(newFiltersEdit.natcos);
        setNatcoRules(newFiltersEdit.natcos);

    };
    const handleRecordChangeEditPre = (
        source: any,
        checked: any,
        updatedRecords: any,
        id: any,
        label: any,
        replaceAll: any, // Flag to indicate whether to replace or add/remove items
    ) => {
        setHasInitialized(true);
        const newFiltersEdit = JSON.parse(JSON.stringify(selectedFiltersEditPre));
        if (replaceAll) {
            // setAll(true);
            // Replace the entire selection
            newFiltersEdit[source] = updatedRecords;
        } else {
            // setAll(false);
            if (checked) {
                newFiltersEdit[source] = [...(newFiltersEdit[source] || []), updatedRecords];
            } else {
                newFiltersEdit[source] = newFiltersEdit[source].filter(
                    (item: any) => item.id !== updatedRecords.id,
                );
            }


        }
        setSelectedFiltersEditPre(newFiltersEdit);
    };


    const handleFilterCancelEditPre = (source: string, id: any) => {
        const newFiltersEdit = JSON.parse(JSON.stringify(selectedFiltersEditPre));
        newFiltersEdit.associatedNatcosEditPre = associatedNatcosEditPre;
        setSelectedFiltersEditPre(newFiltersEdit);
    };

    const handleFilterApplyEditPre = (source: any, id: any) => {
        const newFiltersEdit = JSON.parse(JSON.stringify(selectedFiltersEditPre));
        setAssociatedNatcosEditPre(newFiltersEdit.natcos);

        apiClient
            .post(`/natcos?forForecastDeliveries=true`, newFiltersEdit.natcos.map((nt: any) => ({ ...nt, isInForecastList: true })))
            .then((res: any) => {
                console.log(">>>this");
            })
            .catch((err: any) => {
                console.log(err);
            });


    };
    const handleCancelUpdate = () => {
        setPendingUpdate(null); // Clear the pending update
        setOpenConfirm(false); // Close the dialog
        setUpdateGrid(!updateGrid);
    };

    const handleCancelUpdateProduct = () => {
        setPendingUpdateProduct(null); // Clear the pending update
        setOpenConfirmProduct(false); // Close the dialog
        setUpdateGridProduct(!updateGridProduct);
    };

    const handleDeleteRules = () => {
        apiClient
            .delete(`/productDataRule`)
            .then(() => {
                setDeleteClicked(!deleteClicked);
                setAlert({
                    severity: 'success',
                    message: 'All rules are successfully deleted',
                });
            })
    }

    const handleCancelDeleteRules = () => {
        setOpenConfirmDelete(false); // Close the dialog
    };
    const handleConfirmUpdate = () => {
        if (pendingUpdate) {
            if (!pendingUpdate) return null; // Handle null or undefined gracefully.

            const months = [
                'jan', 'feb', 'mar', 'apr', 'may',
                'jun', 'jul', 'aug', 'sep', 'oct',
                'nov', 'dec'
            ];

            const newProductDataValue = { ...pendingUpdate };
            let totalValue = 0;
            months.forEach((month) => {
                const value = pendingUpdate[month];
                newProductDataValue[month] = value !== null && value !== undefined ? Number(value) : null;
                totalValue += newProductDataValue[month] === null ? 0 : newProductDataValue[month];
            });
            newProductDataValue.isUpdated = true;
            newProductDataValue.total = totalValue;
            if (newProductDataValue.comment?.length > 500) {
                setAlert({
                    severity: 'error',
                    message: "The comment value is too long, it should't exceed 500 characters",
                });
                setUpdateGrid(!updateGrid);
            } else if (newProductDataValue.jan > 2147483647 || newProductDataValue.feb > 2147483647 || newProductDataValue.mar > 2147483647 || newProductDataValue.apr > 2147483647 || newProductDataValue.may > 2147483647 || newProductDataValue.jun > 2147483647 || newProductDataValue.jul > 2147483647 || newProductDataValue.aug > 2147483647 || newProductDataValue.sep > 2147483647 || newProductDataValue.oct > 2147483647 || newProductDataValue.nov > 2147483647 || newProductDataValue.dec > 2147483647) {
                setAlert({
                    severity: 'error',
                    message: "The months values shouldn't exceed 2.147.483.647",
                });
                setUpdateGrid(!updateGrid);


            } else {

                apiClient
                    .post(`natcoData`, [
                        newProductDataValue
                    ])
                    .then(() => {
                        setUpdateGrid(!updateGrid);
                    })

                    .catch((err: any) => {
                        console.log(err);
                    });
                return newProductDataValue;
            }




        }
        setPendingUpdate(null); // Clear the pending update
        setOpenConfirm(false); // Close the dialog
    };
    const handleConfirmUpdateProduct = () => {
        if (pendingUpdateProduct) {
            if (!pendingUpdateProduct) return null; // Handle null or undefined gracefully.
            const newProductValue = { ...pendingUpdateProduct };

            newProductValue.updatedOn = new Date(Date.now());


            apiClient
                .post(`productDataItem`, [
                    newProductValue
                ])
                .then(() => {
                    setUpdateGridProduct(!updateGridProduct);
                })

                .catch((err: any) => {
                    console.log(err);
                });
            return newProductValue;

        }
        setPendingUpdateProduct(null); // Clear the pending update
        setOpenConfirmProduct(false); // Close the dialog
    };



    const processRowUpdate = (productDataValue: any, oldRow: any) => {
        setPendingUpdate(productDataValue); // Save the pending update
        setOpenConfirm(true); // Open the confirmation dialog
        return productDataValue; // Return the new row to show the change in UI temporarily
    };

    const processRowUpdateProduct = (productDataProduct: any, oldRow: any) => {
        setPendingUpdateProduct(productDataProduct); // Save the pending update
        setOpenConfirmProduct(true); // Open the confirmation dialog
        return productDataProduct; // Return the new row to show the change in UI temporarily
    };


    const handleDownload = async () => {

        apiClient
            .get('/natcoData/download-excel', {
                params: { natcoCode: selectedNatco }, // Pass natcoCode as parameter
                responseType: 'blob', // Important for downloading files
            })
            .then((response) => {
                // Create a blob URL for the file and initiate download
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'data.xlsx'); // Set file name
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => {
                console.error('Error downloading file:', error);
            });

    };
    const handleChangePanel =
        (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };



    const currentYear = new Date().getFullYear();

    const years = Array.from({ length: 6 }, (_, i) => currentYear - 2 + i); // Two years before, current year, three years after
    const types = productDataTypes.map((t: any) => t.name); // Three types
    // Initialize the checked state with no years checked
    const [checkedState, setCheckedState] = React.useState(
        Array.from({ length: types.length || 3 }, () =>
            Array.from({ length: years.length }, () => false) // Set all cells to false (unchecked)
        )
    );
    const handleCheckboxChange = (typeIndex: any, yearIndex: any) => {
        const updatedState = checkedState.map((row, t) =>
            row.map((checked, y) =>
                t === typeIndex && y === yearIndex ? !checked : checked
            )
        );
        setCheckedState(updatedState);
    };

    const handleDatahubProductsClean = async () => {
        apiClient.get("/natcoData/cleanDatahubProducts")
            .then((response) => {
                setDatahubCleaned(!dataHubCleaned);
                console.log("response clean datahub", response.data.records);
                setAlert({
                    severity: 'success',
                    message: 'Datahub successfully cleaned',
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const handleDatahubQuantitiesClean = async () => {
        apiClient.get("/natcoData/cleanDatahubQuantities")
            .then((response) => {
                setDatahubCleaned(!dataHubCleaned);
                console.log("response clean datahub", response.data.records);
                setAlert({
                    severity: 'success',
                    message: 'Datahub successfully cleaned',
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const handleSave = async () => {
        setSaveClicked(false);
        // Prepare the data based on the checked boxes
        const dataToSend: any = [];

        let rulesToDelete = '';
        let uniqueIds: any = new Set(); // Use a Set to automatically handle duplicates

        checkedState.forEach((row, typeIndex) => {
            row.forEach((checked, yearIndex) => {
                if (checked) {
                    const productDataType = productDataTypes[typeIndex]; // Assuming each type has an `id` field
                    const year = years[yearIndex];

                    // Loop through all natcos
                    natcoRules.forEach((natco: any) => {
                        if (!uniqueIds.has(natco.id)) {
                            uniqueIds.add(natco.id); // Add ID to the Set
                        }
                        // Push to dataToSend array for each natco
                        dataToSend.push({ productDataType, natco, year });
                    });
                }
            });
        });
        if (dataToSend.length === 0) {
            uniqueIds = natcoRules.map((n: any) => n.id);
        }

        // Convert unique IDs in the Set to a comma-separated string
        rulesToDelete = Array.from(uniqueIds).join(',');

        console.log("dataToSend", dataToSend);
        console.log("rulesToDelete", rulesToDelete);

        //Delete existing rules
        apiClient
            .delete(`/productDataRule/${rulesToDelete}`)
            .then(() => {
                //Add the post to save rules
                apiClient
                    .post(`/productDataRule`, dataToSend)
                    .then((res: any) => {
                        setSaveClicked(true);
                        setAlert({
                            severity: 'success',
                            message: 'Rules successfully inserted',
                        });
                        console.log("Product data rules are successfully inserted")
                    })
                    .catch((err: any) => {
                        console.log(err);
                    });
            })
            .catch((error) => {
                console.log(error);
            })


    }


    const handleChange = (event: any) => {
        setSelectedNatco(event.target.value);
        console.log('Selected Value:', event.target.value);
    };

    const handleDownloadUserGuide = () => {
        apiClient
            .get(`/uploadedFiles/14`, { params: { subPath: 'forecastGuide' } })
            .then((res: any) => {
                if (res.data) {

                    apiClient.get("/uploadedFiles/download/14", {
                        responseType: 'blob',
                        params: { index: 0, subPath: 'forecastGuide' },
                    })
                        .then((res2: any) => {
                            var link = document.createElement('a');
                            link.href = window.URL.createObjectURL(new Blob([res2.data]));
                            link.download = res.data.content[0].name;
                            link.click();
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                }
            })
            .catch((e) => console.log(e));
    };

    const handleAddProduct = () => {
        // Logic for adding a new product
        console.log("Add Product clicked");
        const newRow = {
            id: rowsProducts.length + 1, // Ensure unique ID
            name: '',
            localMaterialCode: '',
            subcategory: { name: '' },
            natco: { code: '' },
            supplier: { name: '' },
        };
        setRowsProducts((prevRows) => [newRow, ...prevRows]);
    };

    return (<>

        <Stack spacing={2} sx={{ m: 2 }}>
            <Stack direction="row" spacing={1} pt={1} alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton
                        onClick={() => {
                            navigate('/domains/tools');
                        }}
                        sx={{
                            color: 'primary.main',
                            '&:hover': {
                                color: 'primary.dark',
                            },
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6">
                        Forecast & Deliveries Data Collection
                    </Typography>
                    <HtmlTooltip
                        title={
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: 'Each "year" shall include all products which are already in shipment and new products which will be launched and rolled-out during that year. </br>  For each product three type of planning items are requested:</br>   1.	DELIVERIES: Deliveries means already received plus planned deliveries (planned deliveries agreed/ confirmed by suppliers) in your local warehouses in your country. </br>  2.	INTERNAL FORECAST: Total internal new purchases forecast [in pcs]. Your internal planned quantities (e.g. based on budget and its regular updates during a year). </br>  3.	SUPPLIER FORECAST: Forecast [in pcs] shared with suppliers. Your forecast quantities that are officially communicated to suppliers (binding plus unbinding forecast)'
                                }}
                            ></span>
                        }
                        arrow
                    >
                        <IconButton>
                            <InfoIcon />
                        </IconButton>
                    </HtmlTooltip>
                </Stack>
                {profile?.isCustomer &&
                    <Tooltip title="Download User Guide">
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" sx={{ marginRight: 1 }}>
                                Download User Guide
                            </Typography>
                            <IconButton
                                onClick={handleDownloadUserGuide} // Define the function to trigger download
                                sx={{
                                    color: 'primary.main',
                                    '&:hover': {
                                        color: 'primary.dark',
                                    },
                                }}
                            >
                                <DownloadIcon />
                            </IconButton>
                        </Box>
                    </Tooltip>
                }
                {!profile?.isCustomer &&
                    <Tooltip title="Open User Guide">
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" sx={{ marginRight: 1 }}>
                                Open User Guide
                            </Typography>
                            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                                <IconButton
                                    sx={{
                                        color: 'primary.main',
                                        '&:hover': {
                                            color: 'primary.dark',
                                        },
                                    }}
                                >
                                    <LinkIcon />
                                </IconButton>
                            </a>
                        </Box>
                    </Tooltip>
                }
            </Stack>
            <Divider />
            {!profile?.isCustomer &&
                <Guard role={Role.ForecastDeliveriesManager}>
                    <>
                        <Accordion
                            expanded={expanded === section.rules}
                            onChange={handleChangePanel(section.rules)}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Define Rules</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RecordFilterSelect
                                    source="natcos"
                                    property={'name'}
                                    label="PreSelect Natcos"
                                    selected={hasInitialized ? selectedFiltersEditPre?.natcos || [] : defaultSelection.natcos || []}
                                    onRecordChange={handleRecordChangeEditPre}
                                    onFilterApply={handleFilterApplyEditPre}
                                    onFilterCancel={handleFilterCancelEditPre}
                                    displaySelectedNumber={false}
                                    forForecastDeliveries={true}
                                />
                                <br />
                                <RecordFilterSelect
                                    source="natcos"
                                    property={'name'}
                                    label="Choose Natco"
                                    selected={selectedFiltersEdit?.natcos || []} // Ensure selected is always an array
                                    onRecordChange={handleRecordChangeEdit}
                                    onFilterApply={handleFilterApplyEdit}
                                    onFilterCancel={handleFilterCancelEdit}
                                    displaySelectedNumber={false}
                                    forForecastDeliveries={true}
                                    recordsSource={associatedNatcosEditPre}
                                />
                                <br />
                                {natcoRules.length > 0 && (
                                    <>
                                        <Typography variant="h6" style={{ display: 'inline-block', marginRight: '8px' }}>
                                            Chosen Natco:
                                        </Typography>
                                        {!isAll && (
                                            <Typography variant="body2" style={{ display: 'inline-block' }}>
                                                {natcoRules.map((n: any) => n.name).join(' - ')}
                                            </Typography>
                                        )}
                                        {isAll && (
                                            <Typography variant="body2" style={{ display: 'inline-block' }}>
                                                ALL
                                            </Typography>
                                        )}
                                    </>
                                )}

                                <br />
                                <br />
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Type</TableCell>
                                                {years.map((year, index) => (
                                                    <TableCell key={index} align="center">
                                                        {year}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {types.length > 0 && types.map((type: any, typeIndex: any) => (
                                                <TableRow key={typeIndex}>
                                                    <TableCell>{type}</TableCell>
                                                    {years.map((_, yearIndex) => (
                                                        <TableCell key={yearIndex} align="center">
                                                            <Checkbox
                                                                checked={checkedState[typeIndex][yearIndex]}
                                                                onChange={() => handleCheckboxChange(typeIndex, yearIndex)}
                                                            />
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { setOpenConfirmDelete(true) }}
                                        sx={{ mb: 2 }}
                                    >
                                        Delete all rules
                                    </Button>

                                    {natcoRules.length > 0 && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSave}
                                        >
                                            Save
                                        </Button>
                                    )}
                                </div>

                                {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}
                                <DataGrid
                                    components={{ Toolbar: CustomToolbar }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: { debounceMs: 500 },
                                        },
                                    }}
                                    autoHeight
                                    rows={rowsRules}
                                    columns={columnsRules}
                                    pageSize={12}
                                    rowsPerPageOptions={[12]}
                                    // checkboxSelection
                                    disableSelectionOnClick
                                    density="compact"
                                />


                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === section.products}
                            onChange={handleChangePanel(section.products)}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Product List</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{ width: '100%' }}>


                                    <UploadForm
                                        pathId={16}
                                        path="productDataItem/upload"
                                        subPath='forecastProducts'
                                        inputId="forecastProducts-sheet"
                                        inputName="forecastProducts"
                                        inputAccept=".xlsx"
                                        onSuccess={(res: any) => {
                                            setRefreshProductList(!refreshproductList);
                                        }}
                                    />

                                    <DataGrid
                                        components={{ Toolbar: GridToolbar }}
                                        componentsProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: { debounceMs: 500 },
                                            },
                                        }}
                                        autoHeight
                                        rows={rowsProducts}
                                        columns={columnsProducts}
                                        pageSize={5}
                                        rowsPerPageOptions={[5]}
                                        checkboxSelection
                                        disableSelectionOnClick
                                        processRowUpdate={processRowUpdateProduct}
                                        experimentalFeatures={{ newEditingApi: true }}
                                        onProcessRowUpdateError={(error) => console.error("Row update error:", error)}

                                    />
                                </div>


                            </AccordionDetails>
                        </Accordion>
                    </>
                </Guard>
            }
            {!profile?.isCustomer &&
                <>
                    <Guard role={Role.ForecastDeliveriesManager}>

                        <Accordion
                            expanded={expanded === section.global}
                            onChange={handleChangePanel(section.global)}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Upload Global Data</Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                <Stack
                                    spacing={2}
                                    // direction="column"
                                    alignItems="flex"
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<FileDownloadIcon />}
                                        sx={{ textTransform: 'none' }}
                                        onClick={handleDownload} // Call handleDownload on click
                                    >
                                        Download existing data
                                    </Button>
                                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                        Please upload the updated file
                                    </Typography>
                                    <UploadForm
                                        pathId={13}
                                        path="natcoData/upload"
                                        subPath='Global'
                                        inputId="natcoData-sheet1"
                                        inputName="natcoData"
                                        inputAccept=".xlsx"
                                        onSuccess={(res: any) => {
                                            setRefreshList(!refreshList);
                                        }}
                                    />
                                    {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}

                                    <DataGrid
                                        // sx={{
                                        //     '& .super-app-theme--sticky-header': {
                                        //         position: 'sticky',
                                        //         left: 0,
                                        //         backgroundColor: '#f4f4f4', // Match the header background
                                        //         zIndex: 1002, // Ensure it's above the body cells
                                        //     },
                                        //     '& .super-app-theme--sticky-cell': {
                                        //         position: 'sticky',
                                        //         left: 0,
                                        //         backgroundColor: 'white', // Prevents overlap
                                        //         zIndex: 1001, // Keeps it above other columns
                                        //         borderRight: '1px solid #ddd', // Optional: separator
                                        //     },
                                        // }}      
                                        components={{ Toolbar: CustomToolbar }}
                                        componentsProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: { debounceMs: 500 },
                                            },
                                        }}
                                        autoHeight
                                        rows={rowsGlobal}
                                        columns={columnsGlobal}
                                        pageSize={12}
                                        rowsPerPageOptions={[12]}
                                        // checkboxSelection
                                        disableSelectionOnClick
                                        processRowUpdate={processRowUpdate}
                                        experimentalFeatures={{ newEditingApi: true }}
                                        density="compact"
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </Guard>

                </>
            }
            <Guard role={Role.ForecastDeliveriesManager}>

                <Accordion
                    expanded={expanded === section.natco}
                    onChange={handleChangePanel(section.natco)}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Upload Data By Natco</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack
                            spacing={2}
                            // direction="column"
                            alignItems="flex"
                        >
                            <FormControl fullWidth error>
                                <InputLabel
                                    id="dropdown-label"
                                    sx={{
                                        fontWeight: 'normal',
                                        '&.Mui-focused': { marginTop: '-17px' }
                                    }}
                                >
                                    Select a Natco
                                </InputLabel>

                                <Select
                                    labelId="dropdown-label"
                                    id="dropdown"
                                    value={selectedNatco}
                                    onChange={handleChange}
                                >
                                    {records.map((record: any) => (
                                        <MenuItem key={record.id} value={record.name}>
                                            {record.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {selectedNatco &&
                                <>
                                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                        Selected Natco: {selectedNatco}
                                    </Typography>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<FileDownloadIcon />}
                                        sx={{ textTransform: 'none' }}
                                        onClick={handleDownload} // Call handleDownload on click
                                    >
                                        Download existing data
                                    </Button>

                                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                        Please upload the updated file
                                    </Typography>

                                    <UploadForm
                                        pathId={13}
                                        path="natcoData/upload"
                                        subPath={selectedNatco}
                                        inputId="natcoData-sheet"
                                        inputName="natcoData"
                                        inputAccept=".xlsx"
                                        onSuccess={() => {
                                            console.log(">>on sucess");
                                            setRefreshList(!refreshList);
                                        }}
                                    /></>}

                        </Stack>
                        {selectedNatco &&
                            <>
                                {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}

                                <DataGrid
                                    components={{ Toolbar: CustomToolbar }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: { debounceMs: 500 },
                                        },
                                    }}
                                    autoHeight
                                    rows={rows}
                                    columns={columns}
                                    pageSize={12}
                                    rowsPerPageOptions={[12]}
                                    // checkboxSelection
                                    disableSelectionOnClick
                                    processRowUpdate={processRowUpdate}
                                    experimentalFeatures={{ newEditingApi: true }}
                                    density="compact"
                                />
                            </>
                        }

                    </AccordionDetails>
                </Accordion>
            </Guard>
            {profile?.isCustomer &&
                <Guard role={Role.ForecastDeliveriesUser}>

                    <Stack
                        spacing={2}
                        // direction="column"
                        alignItems="flex"
                    >

                        <>


                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<FileDownloadIcon />}
                                sx={{ textTransform: 'none' }}
                                onClick={handleDownload} // Call handleDownload on click
                            >
                                Download existing data
                            </Button>

                            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                Please upload the updated file
                            </Typography>

                            <UploadForm
                                pathId={13}
                                path="natcoData/upload"
                                subPath={selectedNatco}
                                inputId="natcoData-sheet"
                                inputName="natcoData"
                                inputAccept=".xlsx"
                                onSuccess={(res: any) => {
                                    setRefreshList(!refreshList);
                                }}
                            /></>

                    </Stack>
                    <>
                        {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}

                        <DataGrid
                            // onSelectionModelChange={(ids: any) => {
                            //   setSelectedIds(ids);
                            // }}
                            components={{ Toolbar: CustomToolbar }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                            autoHeight
                            rows={rows}
                            columns={columns}
                            pageSize={12}
                            rowsPerPageOptions={[12]}
                            checkboxSelection
                            disableSelectionOnClick
                            processRowUpdate={processRowUpdate}
                            experimentalFeatures={{ newEditingApi: true }}
                            density="compact"
                        />
                    </>
                </Guard>
            }

            {!profile?.isCustomer &&
                <Guard role={Role.ForecastDeliveriesManager}>

                    <Accordion
                        expanded={expanded === section.datahub}
                        onChange={handleChangePanel(section.datahub)}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>DataHub interactions</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            <div style={{ display: 'flex', gap: '10px' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleDatahubProductsClean}
                                >
                                    Clean DataHub Products
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleDatahubQuantitiesClean}
                                >
                                    Clean DataHub Quantities
                                </Button>
                            </div>

                            <br />
                            <br />

                            <DataGrid
                                // onSelectionModelChange={(ids: any) => {
                                //   setSelectedIds(ids);
                                // }}
                                components={{ Toolbar: CustomToolbar }}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    },
                                }}
                                getRowId={(row) => row.ProductId}
                                autoHeight
                                rows={rowsDataHubProducts}
                                columns={columnsDataHubProducts}
                                pageSize={12}
                                rowsPerPageOptions={[12]}
                                checkboxSelection
                                disableSelectionOnClick
                                density="compact"
                            // processRowUpdate={processRowUpdate}
                            // experimentalFeatures={{ newEditingApi: true }}
                            />
                            <br />
                            <DataGrid
                                // onSelectionModelChange={(ids: any) => {
                                //   setSelectedIds(ids);
                                // }}
                                components={{ Toolbar: CustomToolbar }}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    },
                                }}
                                getRowId={(row) => row.QuantityId}
                                autoHeight
                                rows={rowsDatahubQuantities}
                                columns={columnsDataHubQuantities}
                                pageSize={12}
                                rowsPerPageOptions={[12]}
                                checkboxSelection
                                disableSelectionOnClick
                                density="compact"
                            // processRowUpdate={processRowUpdate}
                            // experimentalFeatures={{ newEditingApi: true }}
                            />

                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === section.userGuide}
                        onChange={handleChangePanel(section.userGuide)}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>User Guide</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <UploadForm
                                pathId={14}
                                path="natcoData/uploadGuide"
                                subPath="forecastGuide"
                                inputId="forecastGuide-sheet"
                                inputName="forecastGuide"
                                inputAccept=".pdf"
                            />

                        </AccordionDetails>
                    </Accordion>

                </Guard>
            }
            <ConfirmDialog
                title="Confirm Value Update"
                open={openConfirm}
                setOpen={setOpenConfirm}
                onConfirm={handleConfirmUpdate}
                onCancel={handleCancelUpdate}
            >
                <Typography>Are you sure to update this value?</Typography>
            </ConfirmDialog>

            <ConfirmDialog
                title="Confirm Product Update"
                open={openConfirmProduct}
                setOpen={setOpenConfirmProduct}
                onConfirm={handleConfirmUpdateProduct}
                onCancel={handleCancelUpdateProduct}
            >
                <Typography>Are you sure to update this value?</Typography>
            </ConfirmDialog>

            <ConfirmDialog
                title="Confirm Delete All rules"
                open={openConfirmDelete}
                setOpen={setOpenConfirmDelete}
                onConfirm={handleDeleteRules}
                onCancel={handleCancelDeleteRules}
            >
                <Typography>Are you sure to delete all rules?</Typography>
            </ConfirmDialog>

            <>

            </>
            <br />

        </Stack>

    </>)
}