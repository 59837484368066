import { Box, Button, CssBaseline, Grid, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import backgroundLogin from '../images/backgroundLogin.png';

export default function TermsOfUse({ handleAcceptTermsClick }: any) {
  return (
    <Paper
      sx={{
        backgroundImage: `url(${backgroundLogin})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'fixed',
        bottom: 0,
        top: 0,
        height: '100%',
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box
            component="div"
            sx={{
              border: '1px solid grey',
              width: '70%',
              height: '460px',
              marginLeft: '15%',
              overflow: 'hidden',
              overflowY: 'scroll',
              backgroundColor: 'white',
              marginTop: '5%',
              padding: '0.5%',
            }}
          >
            <CssBaseline />
            <Typography variant="h4" component="div" align="center">
              Terms of use
            </Typography>
            <Typography variant="h6" component="div">
              SCOPE OF THIS WEBSITE/ MOBILE APPLICATION
            </Typography>

            <Typography variant="subtitle1" component="div">
              Purpose of my.BuyIn.pro/MyBuyIn is to share relevant sourcing
              information between BuyIn and its customers. By accessing and/or
              using the Website/ mobile app, you acknowledge that you have read,
              understood and agree to be bound by and comply with all terms,
              conditions and notices contained in or referenced by these Terms
              of Use. If you do not agree to these Terms of Use, do not use the
              Website/ mobile app. For all questions or feedback regarding the
              Website/ mobile app and the data processed by the Website/ mobile
              app, please send an e-mail to my@buyin.pro.
            </Typography>

            <Typography variant="h6" component="div">
              Definitions
            </Typography>

            <Typography variant="subtitle1" component="div">
              "User" means an individual who has been granted access to the
              Website/ mobile app by BuyIn. "Personal Data" means any
              information relating to an identified or identifiable individual.
            </Typography>

            <Typography variant="h6" component="div">
              LICENSE TO USE
            </Typography>

            <Typography variant="subtitle1" component="div">
              As an end user, you will receive a unique username and password,
              which is for your personal use only, and may not be shared inside
              or outside your company. The license granted to you for the
              Website/ mobile app (including any of its updates) is limited to a
              personal, non-exclusive, non-transferable, non-sublicensable,
              revocable, limited license to run the Website/ mobile app on your
              system and/or devices solely for the purpose of performing those
              features available to you as a duly authorized and registered end
              user of the Website/ mobile app. This license is granted for the
              duration agreed between BuyIn and your company. BuyIn reserves the
              right to terminate the right to access to and run the Website/
              mobile app at any time, at its sole discretion, without any
              liability on the part of BuyIn. Users shall not use the Website/
              mobile app for any illegal, unauthorized, or unethical purpose,
              including but not limited to the distribution of harmful software
              or the violation of intellectual property rights
            </Typography>

            <Typography variant="h6" component="div">
              INTELLECTUAL PROPERTY RIGHTS
            </Typography>

            <Typography variant="subtitle1" component="div">
              The Website/ mobile app contains information, data, documentation
              and other content (including text, graphics, logos, photos,
              images, etc.) that are protected by trademarks, copyrights and
              intellectual property rights. Your rights are limited to access
              and/or run the Website/ mobile app on your system and/or device as
              described above without granting you any license or right to
              modify, copy, distribute, transmit, display, perform, reproduce,
              publish, license, create derivative works from, transfer or sell
              any data, information, products or services obtained through the
              Website/ mobile app unless otherwise expressly specified in
              separate specific license granted by BuyIn or otherwise mutually
              agreed upon written agreement that you or your company may have
              with BuyIn.
            </Typography>

            <Typography variant="h6" component="div">
              LIABILITY AND DISCLAIMER OF WARRANTY
            </Typography>

            <Typography variant="subtitle1" component="div">
              The information, data, documentation and services made available
              to you pursuant to this Terms of Use are provided “as is” with no
              warranties of any kind. BuyIn expressly disclaims all warranties,
              express and implied including warranty of non-infringement.
            </Typography>
            <Typography variant="subtitle1" component="div">
              BuyIn does not guarantee that the information, data and
              documentation made available on the Website/ mobile app are
              complete, accurate or up-to-date in all cases; the information,
              data and documentation are provided “as up-to-date” and “as
              available”. BuyIn reserves the right to amend, supplement or
              delete the information, data and documentation supplied without
              prior notice.
            </Typography>
            <Typography variant="subtitle1" component="div">
              BuyIn makes no warranty that the Website/ mobile app will be
              available, uninterrupted, timely, secure or error-free. BuyIn will
              not be liable to you under these Terms of Use under any cause of
              action whether in contract, warranty, tort or otherwise for any
              indirect damage, indirect or consequential loss or damage
              (including but not limited to loss of goodwill, loss of
              reputation, loss of data, loss of revenue, loss of profits)
              arising out of or relating to this Terms of Use. The aggregate
              cumulative liability of BuyIn, whether in contract, tort, or
              otherwise for direct damages shall not exceed 100 € (one hundred
              Euros).
            </Typography>
            <Typography variant="subtitle1" component="div">
              BuyIn reserves the right to make changes to the Website/ mobile
              app and those Terms of Use at any time.
            </Typography>

            <Typography variant="h6" component="div">
              CONFIDENTIALITY
            </Typography>

            <Typography variant="subtitle1" component="div">
              Information, data and documentation available through the Website/
              mobile app shall not be copied, reproduced, modified, published,
              broadcasted, posted, transmitted or distributed by decompiling,
              screenshots, photographs, print-outs or by any other means without
              prior written approval of BuyIn. Due to the sensitive nature of
              the business relevant data shared under NDA, you are not allowed
              to disclose any information or discuss information outside the
              Website/ mobile app. The exception of this are the forwarding of
              Information by using the Website/ mobile app, that require the
              receiving party to login in order to see the resulting page.
            </Typography>

            <Typography variant="h6" component="div">
              PRIVACY AND PERSONAL DATA PROTECTION
            </Typography>

            <Typography variant="subtitle1" component="div">
              We are collecting personal information
            </Typography>

            <ul>
              <li>
                to ensure the confidentiality of the business data included in
                this Website/ mobile app,
              </li>
              <li>
                to further develop and tailor our sourcing service offer to you,
                and to analyze the overall user behavior in order to improve the
                user experience of the Website/ mobile app.
              </li>
            </ul>

            <Typography variant="subtitle1" component="div">
              BuyIn is processing personal data on the basis of Art. 6.1. (f)
              Legitimate Interest and Art. 6.1. (a) Consent of the General Data
              Protection Regulation (GDPR) (EU) 2016/679.
            </Typography>
            <Typography variant="subtitle1" component="div">
              What data do we use?
            </Typography>
            <Typography variant="subtitle1" component="div">
              We are collecting two types of information:
            </Typography>
            <ol>
              <li>
                Personal master data: We are managing the following information:
                last name, first name, email, gender, company/NatCo,
                affiliation, contact details, as well as assigned authorization
                details.
              </li>
              <li>
                Personal usage data: We are using the open source software
                Matomo (https://matomo.org) to track the usage of the Website/
                mobile app. The usage data stored by Matomo as described under
                the following link: https://matomo.org/faq/general/faq_18254/{' '}
              </li>
            </ol>
            <Typography variant="subtitle1" component="div">
              Please note that we use your email address as user ID.
            </Typography>

            <Typography variant="subtitle1" component="div">
              Who has access to this data?
            </Typography>

            <ul>
              <li>Personal master data: BuyIn Employees only</li>
              <li>
                Personal usage data: Website/ mobile app product managers and
                Matomo administrators only
              </li>
            </ul>

            <Typography variant="subtitle1" component="div">
              Where is the data processed?
            </Typography>

            <Typography variant="subtitle1" component="div">
              All personal data is stored within the European Union only,
              especially in France and Germany.
            </Typography>

            <Typography variant="subtitle1" component="div">
              How long is the data stored?
            </Typography>

            <ul>
              <li>
                Your personal master data will be store as long as your Website/
                mobile app account is active and exists.
              </li>
              <li>
                Your personal usage data is automatically deleted after 12
                months.
              </li>
            </ul>

            <ul>
              <Typography variant="subtitle1" component="div">
                What data privacy rights do I have?
              </Typography>

              <li>
                Right to request/access information (on the categories of the
                personal data processed, the purposes of the processing, any
                recipients of the data, the envisaged storage period);
              </li>
              <li>
                Right to request that inaccurate or incomplete data be rectified
                or supplemented;
              </li>
              <li>
                Right to withdraw consent at any time with effect for the
                future;
              </li>
              <li>
                Right to request that data be erased, provided that the data is
                no longer required for the intended purpose and/or is being
                unlawfully processed, or you withdraw consent (unless there is
                another legal ground for the processing), or in the case of data
                processing on account of legitimate interests you object to the
                processing and there are no overriding legitimate interests for
                the processing, or data has been unlawfully processed, or the
                personal data has to be erased for compliance with a legal
                obligation;
              </li>
              <li>
                Right to demand under certain circumstances the restriction of
                data processing where erasure is not possible, or the erasure
                obligation is disputed;
              </li>
              <li>
                Right to data portability subject to the conditions of Art. 20
                of the GDPR;
              </li>
              <li>
                Right to object to the processing of your personal data in the
                cases set out in Art. 21 of the General Data Protection
                Regulation;
              </li>
              <li>
                Right to lodge a complaint with a data protection supervisory
                authority regarding the processing of data.
              </li>
            </ul>
            <Typography variant="subtitle1" component="div">
              For expressing your rights, please contact my@buyin.pro
            </Typography>
            <Typography variant="subtitle1" component="div">
              The data protection officer at BuyIn is Dr. Claus D. Ulmer,
              Deutsche Telekom AG, Friedrich-Ebert- Allee 140, 53113 Bonn.{' '}
            </Typography>
            <Typography variant="subtitle1" component="div">
              If you have any queries, please contact compliance@buyin.pro.
            </Typography>

            <Typography variant="h6" component="div">
              SECURITY PRECAUTIONS
            </Typography>

            <Typography variant="subtitle1" component="div">
              BuyIn has taken extensive technical and operational security
              precautions to protect your personal data against unauthorized
              access and misuse.
            </Typography>

            <Typography variant="h6" component="div">
              APPLICABLE LAW AND JURISDICTION
            </Typography>

            <Typography variant="subtitle1" component="div">
              The laws of France will govern these terms, without giving effect
              to any principles of conflicts of laws. Any dispute or difference
              arising out of or in connection with these terms or the Website/
              mobile app including contractual and non-contractual obligations
              shall be resolved by the courts of Paris.
            </Typography>

            <Typography variant="h6" component="div">
              Changes to Terms
            </Typography>

            <Typography variant="subtitle1" component="div">
              BuyIn reserves the right to modify these Terms of Use at any time.
              Continued use of the Website/ mobile app after changes are made
              constitutes acceptance of the revised Terms of Use.
            </Typography>
            <Typography variant="h6" component="div">
              LEGAL NOTICE
            </Typography>

            <Typography variant="subtitle1" component="div">
              This service is created, hosted and provided by BuyIn: BuyIn SAS -
              Address: 12 rue Rouget de Lisle, 92130 Issy-les-Moulineaux, France
            </Typography>
            <Typography variant="subtitle1" component="div">
              Commercial Register Nanterre (RCS) nb.534 960 570
            </Typography>
            <Typography variant="subtitle1" component="div">
              Share capital: EUR 61.500
            </Typography>
            <Typography variant="subtitle1" component="div">
              Publishing Manager: Beatrice Felder
            </Typography>
            <Typography variant="subtitle1" component="div">
              Version date: 21.02.2025
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ maxHeight: '10' }}>
          <Typography align="center">
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'primary',
                marginBottom: '25px',
                marginTop: '25px',
              }}
              onClick={handleAcceptTermsClick}
            >
              Accept Terms Of Use
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
