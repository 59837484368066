import { useMatch } from 'react-router-dom';
import ToolsPage from '../../ToolsPage/ToolsPage';
import ProductCatalogPage from '../../ToolsPage/ProductCatalog/ProductCatalogPage';
import { ReportShow } from '../PowerBiReports/ReportShow';
import InputData from '../../admin/ForecastDeliveriesManager/InputData';
import Bm4xPage from '../../ToolsPage/Bm4x/Bm4xPage';

export default function ToolsTab(props: {
  reclick: any;
  changeReClickVal: any;
}) {
  const reportId = useMatch('/domains/tools/powerBI/:id')?.params.id;
  const productCatalogId = useMatch('/domains/tools/productCatalog/:id')?.params
    .id;
  const forecastDeliveries = useMatch('/domains/tools/forecastDeliveries');
  const bm4x = useMatch('/domains/tools/bm4x');

  return productCatalogId ? (
    <ProductCatalogPage />
  ) : reportId ? (
    <ReportShow reportId={reportId} />
  ) : forecastDeliveries ? (
    <InputData />
  ) : bm4x ? (
    <Bm4xPage />
  ):
  (
    <ToolsPage />
  );
}
