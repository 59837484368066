import EqualizerIcon from '@mui/icons-material/Equalizer';
import { ToolCard } from '../../DomainPage/PowerBiReports/ToolCard';
import { useNavigate } from 'react-router';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
export default function Bm4x() {

  const navigate = useNavigate();


  return (
    <>
      <ToolCard
        label={'BM4X'}
        icon={<AttachMoneyIcon sx={{
          width: 70,
          height: 70,
          padding: '10px',
        }} />}
        color={'rgb(195, 25, 210)'}
        onClick={() => {
          navigate({
            pathname: `/domains/tools/bm4x`,
          });
        }}
      />
    </>
  );
}
