import { useEffect, useState } from "react";
import { TextField, Button, Accordion, AccordionSummary, AccordionDetails, Typography, Stack, IconButton, Divider, Box, AlertColor, Alert } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router";
import UploadForm from "../../admin/Upload/UploadForm/UploadForm";
import apiClient from "../../../services/apiClient";
import { useAuth } from "../../AuthProvider";
import ConfirmDialog from "../../ui/mui/ConfirmDialog/ConfirmDialog";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

export default function MessageList() {
    const [messages, setMessages] = useState<any[]>([]);
    const [bm4xUpdate, setBm4xUpdate] = useState<any>(null);
    const navigate = useNavigate();
    const { profile } = useAuth();
    const [content, setContent] = useState("");
    const [contentHeader, setContentHeader] = useState("");
    const [messageIdToDelete, setMessageIdToDelete] = useState("");
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [alert, setAlert] = useState<{
        severity: AlertColor | undefined;
        message: string;
    }>();

    const { trackPageView } = useMatomo();

    trackPageView({
        documentTitle: 'Tools/BM4X',
        href: `${window.location.origin}/` + 'Tools/BM4X'
      });

    useEffect(() => {
        apiClient
            .get('/bm4x')
            .then((res) => {

                console.log("test", res.data);
                setMessages(res.data.records);
            })
            .catch((e) => {
                console.log(e);
            });
    }, [bm4xUpdate]);

    useEffect(() => {
        if (alert) {
            const timer = setTimeout(() => {
                setAlert(undefined);
            }, 5000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [alert]);

    const handleAddMessage = () => {
        if (content) {

            apiClient
                .post('/bm4x', { header: contentHeader, message: content, by: profile?.email, at: new Date().toLocaleString('en-US') })
                .then(() => {

                    setAlert({
                        severity: 'success',
                        message: 'New Message is saved successfully',
                    });
                    setBm4xUpdate(!bm4xUpdate);
                })
                .catch((e) => {
                    console.log(e);
                });
            setMessages([...messages, content]);
            setContent("");
            setContentHeader("");
        }
    };


    const handleNotifyCustomers = async () => {
        apiClient
            .post('/bm4x/notify-customers', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ message: 'A new version is uploaded' })
            })
            .then(() => {

                setAlert({
                    severity: 'success',
                    message: 'Notification sent to customers!',
                });
            })
            .catch((e) => {
                console.log(e);
            });

    };


    const handleDeleteMessage = () => {
        apiClient
            .delete(`/bm4x/${messageIdToDelete}`)
            .then(() => {
                setAlert({
                    severity: 'success',
                    message: 'The selected message is successfully deleted',
                });
                setMessages(messages.filter(message => message.id !== messageIdToDelete));
            })
    }

    const handleCancelDeleteMessage = () => {
        setOpenConfirmDelete(false); // Close the dialog
    };


    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center">
                <IconButton
                    onClick={() => {
                        navigate('/domains/tools');
                    }}
                    sx={{
                        color: 'primary.main',
                        '&:hover': {
                            color: 'primary.dark',
                        },
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6">BM4X Information</Typography>
            </Stack>
            <Divider />

            {/* Add Messages Panel */}
            <Box sx={{ m: 2 }}>
                {!profile?.isCustomer &&
                    <>
                        <Typography variant="h6" gutterBottom>
                            Add Messages
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Enter Header of the message
                        </Typography>



                        <ReactQuill
                            value={contentHeader}
                            onChange={setContentHeader}
                            modules={{
                                toolbar: [
                                    [{ header: [1, 2, false] }],
                                    ["bold", "italic", "underline"],
                                    [{ list: "ordered" }, { list: "bullet" }],
                                    // ["link"],
                                ],
                            }}
                            style={{ marginBottom: '10px' }}

                        />

                        <Typography variant="subtitle1" gutterBottom>
                            Enter content of the message
                        </Typography>

                        <ReactQuill
                            value={content}
                            onChange={setContent}
                            modules={{
                                toolbar: [
                                    [{ header: [1, 2, false] }],
                                    ["bold", "italic", "underline"],
                                    [{ list: "ordered" }, { list: "bullet" }],
                                    // ["link"],
                                ],
                            }}
                            style={{ marginBottom: '10px' }}

                        />


                        <Button variant="contained" color="primary" fullWidth onClick={handleAddMessage}>
                            Add Message
                        </Button>
                    </>
                }

                {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}

                {/* Scrollable Message Box */}
                <Box
                    sx={{
                        mr: 3.4,
                        ml: 2.7,
                        marginTop: 2,
                        maxHeight: 250, // Adjust height as needed
                        overflowY: 'auto',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        padding: '10px',
                    }}
                >
                    {messages.length === 0 && <Typography>No message is added</Typography>}
                    {messages.map((msg: any, index) => (

                        <Accordion key={index}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
                                    <div dangerouslySetInnerHTML={{ __html: msg.header }} />
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="body2" color="textSecondary" sx={{ marginRight: 2 }}>
                                            AT: {msg.by} - BY: {msg.at}
                                        </Typography>
                                        {!profile?.isCustomer &&
                                            <IconButton
                                                size="small"
                                                onClick={() => { setMessageIdToDelete(msg.id); setOpenConfirmDelete(!openConfirmDelete); }}
                                            >
                                                <DeleteIcon sx={{ color: "primary.main" }} />
                                            </IconButton>
                                        }
                                    </Box>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div dangerouslySetInnerHTML={{ __html: msg.message }} />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </Box>



            {/* Upload Tool Section */}
            <Box sx={{ m: 2 }}>
                {!profile?.isCustomer &&
                    <>
                        <Typography variant="h6" gutterBottom>
                            Upload BM4X Documents
                        </Typography>
                        {/* Notify Customers Button */}
                        <Box sx={{ mb: 2 }}>
                            <Stack direction="row" justifyContent="flex-end">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={handleNotifyCustomers}
                                >
                                    Notify Customers
                                </Button>
                            </Stack>
                        </Box>
                    </>
                }

                <UploadForm
                    pathId={15}
                    path="bm4x/upload"
                    subPath="BM4X"
                    inputId="bm4x-sheet"
                    inputName="bm4x"
                    inputAccept=".doc,.docx,.xls,.xlsx,.pdf"
                />

                <ConfirmDialog
                    title="Confirm Delete Message"
                    open={openConfirmDelete}
                    setOpen={setOpenConfirmDelete}
                    onConfirm={handleDeleteMessage}
                    onCancel={handleCancelDeleteMessage}
                >
                    <Typography>Are you sure to delete the selected message?</Typography>
                </ConfirmDialog>
            </Box>
        </>

    );
}
